import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface UserNotification {
  id: number;
  is_read: boolean;
  user_id: number;
  title: string;
  message: string;
  created_at: string;
}

export function NotificationsAPI(baseUrl: string) {
  return {
    list: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/notifications/?renter_id=${renterId}`, undefined, true) as ApiResponse<UserNotification[]>;
    },
    markAllAsRead: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.put(`${baseUrl}/notifications/?renter_id=${renterId}`, {}, true) as string;
    }
  }
}