import { Outlet, useNavigate } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import { ToastContainer } from "react-toastify";
import { NextUIProvider } from "@nextui-org/react";

export function AuthLayout({ children }: { children?: React.ReactNode; }) {
  const darkMode = useDarkMode(false);
  const navigate = useNavigate();
  
  return (
    <>
      <NextUIProvider navigate={navigate}>
        <main id="main" className={`${darkMode.value ? 'dark bg-black' : 'bg-zinc-100'} text-foreground max-h-dvh`}>
          <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
          <>
            {children || <Outlet />}
          </>
        </main >
      </NextUIProvider>
    </>
  );
}
