import { useDisclosure } from "@nextui-org/react";
import { toast } from "react-toastify";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { api } from "@/api";
import { getIdFromOfficeHandbook, getIdFromCategoryHandbook } from "@/utils/getIdFromHandbook.ts";
import { InputField } from "../../forms/formBuilder";
import { FormModalBuilder, ModalError } from "../formModalBuilder";
import { useState } from "react";
import useErrorHandling from "@/hooks/useErrorHandling";

interface CreateTicketForm {
  title: string;
  description: string;
  category: number;
  office: number;
  user: number;
}

const createTicketFields: InputField[] = [
  {
    label: "Тема обращения",
    name: "title",
    placeholder: "Введите тему обращения",
    type: "text"
  }, {
    label: "Описание",
    name: "description",
    placeholder: "Введите описание обращения",
    type: "text"
  }, {
    label: "Категория",
    name: "category",
    placeholder: "",
    type: "category"
  }, {
    label: "Офис",
    name: "office",
    placeholder: "",
    type: "office"
  }
]

export function CreateTicketModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const user = useAppSelector(state => state.user.currentUser.id);

  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  const handleError = useErrorHandling();

  const onSubmit = (data: CreateTicketForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const office = await getIdFromOfficeHandbook(String(data.office));
      const category = await getIdFromCategoryHandbook(String(data.category));

      api.tickets.create({
        title: data.title,
        category: Number(category),
        description: data.description,
        status: "open",
        office: Number(office),
        user: user!
      })
        .then(response => {
          api.tickets.messages.create(response.data.id, {
            message: data.description,
            ticket_id: String(response.data.id),
            sender: String(user!)
          })
            .then(_data => {
              disclosure.onClose();
              resolve(true);
            })
            .catch(err => {
              console.log(err);
              const { errorMessage, parsedErrors } = handleError(err);
              setModalErros(parsedErrors || []);
              toast.error(errorMessage);
              resolve(false);
            });
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <FormModalBuilder modalErrors={modalErrors} title="Создание тикета" fields={createTicketFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
} 