import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OfficeLocation } from "@/api/locations/locations.ts";
import { Office } from "@/api/officies/officies.ts";
import { Renter } from "@/api/renters/renters.ts";
import { TicketCategory } from "@/api/tickets/tickets.ts";
import { User } from "@/api/auth/auth.ts";

export interface UserRole { display_name: string, id: number };
export interface OfficeType { display_name: string, id: number };

type TicketCategoryStore = TicketCategory[];
type LocationStore = OfficeLocation[];
type OfficeStore = Office[];
type OfficeTypeStore = OfficeType[];
type RenterStore = Renter[];
type UserStore = User[];
type UserRoleStore = UserRole[];

interface HandbooksStoreState {
  ticketCategory: TicketCategoryStore,
  location: LocationStore,
  office: OfficeStore,
  officeType: OfficeTypeStore,
  renter: RenterStore,
  user: UserStore,
  userRole: UserRoleStore
};

const initialState: HandbooksStoreState = {
  ticketCategory: [],
  location: [],
  office: [],
  officeType: [
    {
      "display_name": "Кабинет",
      "id": 1
    },
    {
      "display_name": "Переговорная комната",
      "id": 2
    },
    {
      "display_name": "Офис",
      "id": 3
    },
    {
      "display_name": "Опен-спейс",
      "id": 7
    }
  ],
  renter: [],
  user: [],
  userRole: [
    {
      "display_name": "Представитель арендатора",
      "id": 1
    },
    {
      "display_name": "Сотрудник",
      "id": 2
    },
    {
      "display_name": "Арендатор",
      "id": 3
    }
  ]
};

const handbooksSlice = createSlice({
  name: 'handbooks',
  initialState,
  reducers: {
    setTicketCategories(state, action: PayloadAction<TicketCategoryStore>) {
      state.ticketCategory = action.payload;
    },
    setLocations(state, action: PayloadAction<LocationStore>) {
      state.location = action.payload;
    },
    setOffices(state, action: PayloadAction<OfficeStore>) {
      state.office = action.payload;
    },
    setOfficeTypes(state, action: PayloadAction<OfficeTypeStore>) {
      state.officeType = action.payload;
    },
    setRenters(state, action: PayloadAction<RenterStore>) {
      state.renter = action.payload;
    },
    setUsers(state, action: PayloadAction<UserStore>) {
      state.user = action.payload;
    },
    setUserRoles(state, action: PayloadAction<UserRoleStore>) {
      state.userRole = action.payload;
    }
  }
});

export const {
  setTicketCategories,
  setLocations,
  setOffices,
  setOfficeTypes,
  setRenters,
  setUsers,
  setUserRoles
} = handbooksSlice.actions;

export default handbooksSlice.reducer;