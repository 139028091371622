import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { TableBuilder } from "../../tables/tableBuilder";
import { LocationChip } from "../locationChip";
import { OfficeTypeChip } from "../officeTypeChip";
import { Office } from "@/api/officies/officies.ts";
import { ColumnType } from "../../tables/tableTypes";

export function OfficeModal({ isOpen, onOpenChange, item }: { isOpen: boolean, onOpenChange: (open: boolean) => void, item: Office | null }) {
  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} portalContainer={document.getElementById("main")!} backdrop="blur" scrollBehavior="outside" size="4xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Просмотр офиса</ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-1 mb-2">
                  <TableBuilder
                    columns={[
                      {
                        key: "display_name",
                        label: "Название",
                        type: ColumnType.String
                      },
                      {
                        key: "area",
                        label: "Площадь",
                        type: ColumnType.Number
                      },
                      {
                        key: "price",
                        label: "Цена",
                        type: ColumnType.Number
                      },
                      {
                        key: "floor",
                        label: "Этаж",
                        type: ColumnType.Number
                      },
                      {
                        key: "room_count",
                        label: "Комнаты",
                        type: ColumnType.Number
                      },
                      {
                        key: "office_type",
                        label: "Тип офиса",
                        render(value, _row) {
                          return <OfficeTypeChip officeTypeId={value} />
                        },
                        type: ColumnType.Custom
                      },
                      {
                        key: "location",
                        label: "Локация",
                        render(value, _row) {
                          return <LocationChip locationId={value} />
                        },
                        type: ColumnType.Custom
                      }
                    ]}
                    data={[item]}
                    />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button variant="flat" color="default" onClick={onClose}>Закрыть</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}