import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@/api/auth/auth.ts';

interface UserState {
  currentUser: Partial<User>,
  permissions: {
    role: string,
    scopes: string[]
  }
}

const initialState: UserState = {
  currentUser: {
    first_name: "Not",
    last_name: "Loaded",
    middle_name: "Yet"
  },
  permissions: {
    role: '',
    scopes: []
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.currentUser = action.payload;
    },
    setPermissionScopes(state, action: PayloadAction<string[]>) {
      state.permissions.scopes = action.payload;
    },
    setPermissionRole(state, action: PayloadAction<string>) {
      state.permissions.role = action.payload;
    }
  },
});

export const { setUser, setPermissionScopes, setPermissionRole } = userSlice.actions;

export default userSlice.reducer;