import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Divider } from "@nextui-org/react";
import { getNormalizedDate } from "@/utils";
import { Ticket } from '@/api/tickets/tickets.ts';
import { api } from '@/api';
import { toast } from 'react-toastify';
import { Office } from '@/api/officies/officies.ts';
import { LocationChip } from '../chips/locationChip';

export interface IAttachment {
  name: string;
  url: string;
  type: "image" | "file";
}

export interface IMessage {
  id: number;
  message: string;
  user: string;
  date: string;
  isMe: boolean;
}

export interface IChat {
  id: number;
  title: string;
  ticket: Ticket | null;
  messages: IMessage[];
  adminView: boolean;
}

export function Chat({ data }: { data: IChat }) {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [office, setOffice] = useState<Office | null>(null);
  
  useEffect(() => {
    api.officies.get(data.ticket?.office || 0)
      .then(response => {
        setOffice(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [data]);

  const closeTicket = useCallback(() => {
    api.tickets.close(data.ticket?.id || 0)
      .then(_results => {
        window.location.reload();
      })
      .catch(err => {
        toast.error("Произошла ошибка при закрытии тикета!");
        console.log(err);
      });
  }, [data.ticket]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [data.messages]);

  return (
    <div className="flex flex-col flex-grow gap-2 max-h-[calc(100vh-20%)] overflow-hidden">
      <span className="text-2xl font-bold">Обращение «{data.title}»</span>
      <div className="flex flex-row gap-2 overflow-hidden">
        <div className="flex flex-col p-2 max-w-4xl flex-grow max-h-[calc(100vh-30%)] overflow-auto">
          {data.messages.map((message) => (
            <Message key={message.id} adminView={data.adminView} message={message} />
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="flex flex-col gap-2 h-fit ml-4 bg-white dark:bg-zinc-900 p-8 rounded-xl">
          <div className='mb-4 flex flex-col gap-2'>
            <span className="text-2xl font-bold mt-2">Описание</span>
            <span>{data.ticket?.description || 'н/д'}</span>
          </div>
          {data.ticket?.status == "open" && (
            <Button className="font-medium mb-4" color="danger" variant="flat" size="md" onClick={closeTicket}>Закрыть обращение</Button>
          )}
          <Divider />
          <span className="text-2xl font-bold mt-2">Офис</span>
          <div className="flex flex-col">
            <span className="font-medium">{office?.display_name}</span>
            <span className="font-medium mt-4"><LocationChip locationId={office?.location || 0} /></span>
          </div>
        </div>
      </div>
    </div>
  );
}

function getNormalizedUsername(username: string) {
  switch (username) {
    case 'user':
      return "Пользователь"
    case 'admin':
      return "Администратор"
  }
}

export function Message({ adminView, message }: { adminView: boolean, message: IMessage }) {
  return (
    <div className={`flex flex-col w-full items-${!adminView && message.isMe ? 'end' : 'start'}`}>
      <div className={`flex flex-col items-${!adminView && message.isMe ? 'end' : 'start'} gap-2 p-2 max-w-lg`}>
        <span className="text-base font-medium">
          {adminView ? message.user : (message.isMe ? 'Вы' : getNormalizedUsername(message.user))}, {getNormalizedDate(message.date)}
        </span>
        <div className={`flex flex-row min-w-32 gap-2 px-3 py-2 ${!adminView && message.isMe ? 'bg-primary' : 'bg-white dark:bg-zinc-800'} rounded-xl`}>
          <span className={`text-base font-normal text-${!adminView && message.isMe ? 'right' : 'left'} text-${!adminView && message.isMe ? 'background' : 'foreground'}`}>
            {message.message}
          </span>
        </div>
      </div>
    </div>
  );
}
