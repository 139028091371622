import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { api } from "@/api";
import { Office } from "@/api/officies/officies.ts";
import { SharedLock as SharedLockType } from "@/api/sharedLocks/sharedLocks";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { ColumnType } from "../../components/tables/tableTypes";
import { FaArrowRightToBracket, FaLockOpen } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export function LocksPage() {
  const [offices, setOffices] = useState<Office[]>([]);
  const [sharedLocks, setSharedLocks] = useState<SharedLockType[]>([]);
  const [data, setData] = useState<{id: Number, displayName: string, shared: boolean}[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const collection = [
      ...offices.map(el => { return { id: el.id, displayName: el.display_name, shared: false }}),
      ...sharedLocks.map(el => { return { id: el.id, displayName: el.display_name, shared: true }}),
    ];

    setData(collection);
  }, [offices, sharedLocks]);

  const openLock = useCallback((id: number) => {
    api.locks.unlock(id)
      .then(data => {
        console.log(data);
        toast.success("Замок был успешно открыт!");
      })
      .catch(err => {
        console.log(err);
        toast.error("При открытии замка произошла ошибка!");
      });
  }, []);

  const openSharedLock = useCallback((id: number) => {
    api.sharedLocks.unlock(id)
      .then(data => {
        console.log(data);
        toast.success("Замок был успешно открыт!");
      })
      .catch(err => {
        console.log(err);
        toast.error("При открытии замка произошла ошибка!");
      });
  }, []);

  useEffect(() => {
    api.officies.list()
      .then(data => {
        setOffices(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке офисов!");
      });

    api.sharedLocks.get()
      .then(data => {
        setSharedLocks(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке общих замков!");
      });
  }, []);

  return (
    <div className="flex flex-row flex-wrap gap-4 w-full">
      <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-medium">Замки</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            displayOptions={false}
            displaySearch={false}
            columns={[
              {
                type: ColumnType.String,
                label: "Название",
                key: "displayName",
                sortable: true,
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaLockOpen />,
                    onClick(_value, row) {
                      if(row.shared) {
                        openSharedLock(row.id);
                      } else {
                        openLock(row.id);
                      }
                    },
                  },
                  {
                    icon: <FaArrowRightToBracket />,
                    onClick(_value, row) {
                      navigate("/dashboard/lock/" + row.id)
                    },
                  }
                ]
              }
            ]}  
            data={data}
            removeWrapper
          />
        </div>
      </div>
    </div>
  )
}
