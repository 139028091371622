import { Button } from "@nextui-org/react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa6";
import { Column, ColumnType, TableAction } from "./tableTypes";
import { getNormalizedDate, getNormalizedDateTime, isActionsColumn } from "@/utils";
import { CategoryChip } from "../chips/categoryChip";
import { LocationChip } from "../chips/locationChip";
import { OfficeChip } from "../chips/officeChip";
import { OfficeTypeChip } from "../chips/officeTypeChip";
import { RenterChip } from "../chips/renterChip";
import { UserChip } from "../chips/userChip";

export function CopyableElement({ children }: { children: any }) {
  return (
    <div className="flex flex-row gap-2 items-center">
      {children}
      {(String(children).trim() == "" ? '' :
        <CopyToClipboard text={children}>
          <FaCopy
            className="text-foreground-400 hover:text-foreground-500 cursor-pointer transition-all"
          />
        </CopyToClipboard>
      )}
    </div>
  );
}

export function ActionsElement({ actions, value, row }: { actions: TableAction[], value: any, row: any }) {
  return (
    <div className="flex flex-row gap-2 items-center w-full">
      {actions.map(action => (
        <Button size="sm" variant="flat" onClick={() => action.onClick(value, row)}>
          {action.icon}
        </Button>
      ))}
    </div>
  )
}


export function renderCell(row: any, column: Column) {
  switch (column.type) {
    case ColumnType.Date:
      return <CopyableElement>{getNormalizedDate(row[column.key])}</CopyableElement>;

    case ColumnType.DateTime:
      return <CopyableElement>{getNormalizedDateTime(row[column.key])}</CopyableElement>;

    case ColumnType.Number:
      return <CopyableElement>{row[column.key].toString()}</CopyableElement>;

    case ColumnType.Boolean:
      return <CopyableElement>{row[column.key] ? "да" : "нет"}</CopyableElement>;

    case ColumnType.String:
      return <CopyableElement>{row[column.key]}</CopyableElement>;

    case ColumnType.User:
      return <UserChip userId={row[column.key]} />;

    case ColumnType.Renter:
      return <RenterChip renterId={row[column.key]} />;

    case ColumnType.OfficeType:
      return <OfficeTypeChip officeTypeId={row[column.key]} />;

    case ColumnType.Location:
      return <LocationChip locationId={row[column.key]} />;

    case ColumnType.Category:
      return <CategoryChip categoryId={row[column.key]} />;

    case ColumnType.Office:
      return <OfficeChip officeId={row[column.key]} />;

    case ColumnType.Actions:
      //@ts-ignore
      return <ActionsElement actions={isActionsColumn(column) ? column.actions : []} row={row} value={row[column.key]} />;

    case ColumnType.Custom:
      return column.render!(row[column.key], row);

    default:
      return "н/д";
  }
}