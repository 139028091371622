import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure, Input } from "@nextui-org/react";
import { RenterChip } from "@/app/components/chips/renterChip";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { useCallback, useState } from "react";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";

export function TransferOwnershipModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const renterId = useAppSelector(state => state.location.location.id);
  const [username, setUsername] = useState<string>("");
  const handleError = useErrorHandling();

  const transfer = useCallback(() => {
    api.renters.transferOwnership(renterId, username)
      .then(_res => {
        toast.success("Компания успешно перенесена!");
        location.href = "/dashboard/selectLocation";
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      })
  }, [username]);

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onOpenChange={disclosure.onOpenChange}
      portalContainer={document.getElementById("main")!}
      backdrop="blur"
      scrollBehavior="outside"
    >
      <ModalContent>
        {(_onClose) => (
          <>
            <ModalHeader>
              <span>Передача владения компанией</span>
            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col gap-4">
                <span>Вы собираетесь передать права владения вашей компанией <RenterChip renterId={renterId} /></span>
                <span>Эту операцию невозможно отменить - в случае ошибки вам придётся связываться с технической поддержкой</span>
                <span>Введите логин пользователя, которому хотите передать права:</span>
                <Input
                  placeholder="Введите логин пользователя"
                  variant="bordered"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="w-full flex flex-row gap-2">
                <Button
                  color="primary"
                  variant="flat"
                  className="w-full"
                  onClick={transfer}
                  isDisabled={!username}
                >
                  Подтвердить передачу
                </Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
