import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { LockPasscode } from "@/api/locks/locks.ts";

interface IEditPasscodeForm {
  passcode: string;
}

const editPasscodeFields: InputField[] = [
  {
    label: "Пароль",
    name: "passcode",
    placeholder: "Офис 1",
    type: "text",
    isRequired: true
  }
]

export function EditPasscodeModal({ disclosure, lock, passcode }: { disclosure: ReturnType<typeof useDisclosure>, lock: number, passcode: LockPasscode | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: IEditPasscodeForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.locks.updatePasscode(lock, passcode!.id, {
        passcode: data.passcode
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.locks.deletePasscode(lock, passcode!.id)
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder
      title="Редактирование пароля"
      fields={editPasscodeFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        passcode: passcode?.passcode
      }} />
  );
}