import { useNavigate } from "react-router-dom";
import { FaDoorClosed, FaExpand } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, useDisclosure } from "@nextui-org/react";
import { api } from "@/api";
import { OfficeLocation } from "@/api/locations/locations.ts";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { ColumnType } from "../../components/tables/tableTypes";

export function LocationsPage() {
  const [locations, setLocations] = useState<OfficeLocation[]>([]);
  const navigate = useNavigate();

  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.locations.list()
      .then(data => {
        setLocations(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке локаций!");
      });
  }, []);

  return (
    <div className="flex flex-row flex-wrap gap-4 w-full">
      <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-medium">Локации</span>
            <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}>
              <FaExpand />
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже отображены доступные вам локации.</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            removeWrapper
            displayOptions={false}
            columns={[
              {
                label: "Название",
                key: "display_name",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Адрес",
                key: "address",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Город",
                key: "city",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Станция метро",
                key: "metro_station",
                type: ColumnType.String,
                sortable: true
              },
              {
                label: "Район",
                key: "district",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaDoorClosed />,
                    onClick: (_value, row) => { navigate(`/dashboard/locations/${row.id}/offices`) }
                  }
                ]
              }
            ]}
            data={locations}
          />
        </div>
      </div>
    </div>
  )
}
